<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Подборки и рекомендации</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-2">
      <v-col cols="10">
        <v-text-field v-model="search"
                      hide-details
                      label="Поиск"
                      outlined
                      rounded
                      dense
                      prepend-inner-icon="mdi-magnify" />
      </v-col>
      <v-col class="row no-gutters justify-center d-flex align-center" cols="2">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded color="primary" dark to="/recommendations/new">
          <div class="font-weight-bold hidden-xs-only">Создать</div>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="mx-1 hidden-xs-only">
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="caption">Изображение</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-start">
        <span class="caption">Подборка</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-space-around">
        <div class="caption pr-3">Пр.</div>
        <div class="caption pr-3">Акт.</div>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center">
        <span class="caption">Описание</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center">
        <span class="caption">Заведение</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="caption">Редактировать</span>
      </v-col>
    </v-row>
    <div v-for="item in filteredList" :key="item.id">
      <row :item="item"/>
    </div>
  </v-card>
</template>


<script>
import row from "./row";

export default {
  components: { row },
  data() {
    return {
      search: "",
      createDialog: false
    }
  },
  computed: {
    list() {
      return this.$store.getters["recommendations/list"];
    },
    filteredList() {
      if (!this.search) {
        return this.list;
      }
      return this.list.filter(row => row.title.indexOf(this.search) > 0);
    }
  },
  mounted() {
    this.$store.dispatch("recommendations/fetchList");
    this.$store.dispatch("stores/fetch");
  }
}
</script>

<template>
  <v-row :class="$vuetify.breakpoint.xs ? 'pa-2' : ''" no-gutters v-if="item" class="grey lighten-4 mb-2 mx-1">
    <v-col cols="4" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Изображение:</span>
    </v-col>
    <v-col cols="8" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
      <v-avatar height="96" rounded width="121">
        <img v-if="item.image" :src="item.image" alt="" @click="handleImageClick(item)"/>
        <img v-else
             src="https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg"
             alt="" @click="handleImageClick(item)"/>
      </v-avatar>
      <div v-show=false>
        <input :id="`image-${item.id}`"
               :ref="`image-${item.id}`"
               accept="image/*"
               type="file"
               @change="handleImageUpload(item)"/>
      </div>
    </v-col>
    <v-col cols="4" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Подборка:</span>
    </v-col>
    <v-col cols="8" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start align-center d-flex">
      <span :class="$vuetify.breakpoint.xs ? 'pt-1 text-body-2' : ''">{{ item.title }}</span>
    </v-col>
    <v-col cols="12" class="col col-lg-1 col-md-1 sm-1 row no-gutters justify-space-around align-center d-flex">
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Приоритет:</span>
      </v-col>
      <v-col cols="8" class="row no-gutters col col-lg-6 col-md-6 col-sm-6 justify-end justify-lg-center justify-md-center justify-sm-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1 text-body-2' : ''">{{ item.priority }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Активный:</span>
      </v-col>
      <v-col cols="8" class="row no-gutters col col-lg-6 col-md-6 col-sm-6 justify-end justify-lg-center justify-md-center justify-sm-center">
        <v-checkbox v-model="item.active" hide-details class="ma-0 pa-0" @change="activate(item)"/>
      </v-col>
    </v-col>
    <v-col cols="4" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Описание:</span>
    </v-col>
    <v-col cols="8" class="col col-lg-3 col-md-3 sm-3 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center align-center d-flex">
      <span :class="$vuetify.breakpoint.xs ? 'text-end font-weight-bold' : 'px-3'" class="caption">{{ item.description }}</span>
    </v-col>
    <v-col cols="3" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Заведение:</span>
    </v-col>
    <v-col :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" cols="9" class="col col-lg-3 col-md-3 sm-3 row no-gutters justify-center align-center d-flex">
      <div :class="$vuetify.breakpoint.xs ? 'text-end' : 'text-center'" class="col-12" v-for="(store, i) in item.stores" :key="store.id">
        <span class="text-body-2 text-no-wrap">{{ i + 1 }}. {{ getStore(store.store_id) }}</span>
      </div>
    </v-col>
    <v-col cols="4" class="row no-gutters hidden-sm-and-up">
      <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Редактировать:</span>
    </v-col>
    <v-col cols="8" class="col col-lg-1 col-md-1 sm-1 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around align-center d-flex">
      <v-btn fab :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.xsOnly" @click="edit(item)" :class="$vuetify.breakpoint.xs ? 'mr-3' : ''">
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.xsOnly" @click="delRec(item.id)">
        <v-icon color="error">mdi-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      showDelDialog: false
    }
  },
  mounted() {
    this.$store.dispatch("stores/fetch");
  },
  computed: {
    stores() {
      return this.$store.getters["stores/list"];
    }
  },
  methods: {
    activate(item) {
      if (item.active) {
        this.$store.dispatch("recommendations/enable", item.id);
      } else {
        this.$store.dispatch("recommendations/disable", item.id);
      }
    },
    edit(item) {
      this.$router.push({name: 'editRecommendation', params: {id: item.id}});
    },
    delRec(id) {
      this.$confirm(
          "Удалить ?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("recommendations/delete", id);
      })
    },
    handleImageClick(item) {
      this.$refs["image-" + item.id].click();
    },
    handleImageUpload(item) {
      let file = this.$refs["image-" + item.id].files[0];
      if (file) {
        this.$store.dispatch("recommendations/upload", {id: item.id, file: file});
      }
    },
    getStore(id) {
      let name = '';
      if (this.stores && this.stores.length > 0) {
        this.stores.forEach(s => {
          if (s.id === id) {
            name = s.name;
          }
        })
      }
      return name;
    }
  }
}
</script>
